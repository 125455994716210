import React from 'react';
import styles from './index.module.scss';


const AboutProject = () => (
  <section className={styles.wrapper}>
    <section className={styles.aboutSection}>
      <img src="/images/partners.png" alt="Partneri" />
      <section className={styles.aboutSectionContent}>
        <header>
          <p>Projekt:</p>
          <h2>
            Právne služby ako prevencia a eliminácia diskriminácie
          </h2>
        </header>
        <section>
          Cieľ: <br />
          Cieľom projektu je prevencia a eliminácia všetkých foriem diskriminácie a naplnenie Merateľných ukazovateľov Projektu definovaných v Prílohe č. 2 Zmluvy o poskytnutí NFP, a to podľa času plnenia Merateľného ukazovateľa, buď k dátumu Ukončenia realizácie hlavných aktivít Projektu, alebo po Ukončení realizácie hlavných aktivít Projektu.
        </section>
        <section>
          <p>
            <span><b>Prijímateľ:</b></span>
            <span>Advokátska kancelária SEMANČÍN & PARTNERS s. r. o.</span>
          </p>
          <p>
            <span><b>Nenávratný finančný príspevok: </b></span>
            <span>186 164,35 EUR</span>
          </p>
          <p>
            <span><b>Výška finančnej podpory z EÚ: </b></span>
            <span>186 164,35 EUR</span>
          </p>
        </section>
      </section>
    </section>
  </section>
);

export default AboutProject;
